import { defineStore } from 'pinia'
import {computed, ComputedRef, reactive, Ref, ref} from 'vue'
import userService from '@/services/user.service'
import jwtDecode from "jwt-decode";

export interface AuthTokens {
    access: string
    refresh: string
}

export interface Auth {
    accessToken: Ref<string | null>
    refreshToken: Ref<string | null>
    returnUrl: Ref<string>
    needAuth: Ref<boolean>
    isAvailableComments: ComputedRef<boolean>
    setRefreshedTokens: (tokens: AuthTokens) => void
    login: (login: string, password: string) => void
    logout: () => void
}

export const useAuthStore = defineStore('auth', (): Auth => {
    const accessToken = ref(localStorage.getItem('access_token'))
    const refreshToken = ref(localStorage.getItem('refresh_token'))
    const returnUrl = ref('')
    const needAuth = ref(!accessToken.value)
    const actions = ref(JSON.parse(localStorage.getItem('actions') || '[]'))

    const isAvailableComments = computed(() => !!actions.value.find((e: string) => e === 'C_PC'))


    const setRefreshedTokens = (tokens: AuthTokens) => {
        accessToken.value = tokens.access
        refreshToken.value = tokens.refresh
        localStorage.setItem('access_token', tokens.access)
        localStorage.setItem('refresh_token', tokens.refresh)
    }

    const login = (login: string, password: string) => {
        userService.login(login, password)
            .then(({ data }) => {
                setRefreshedTokens({ access: data.access, refresh: data.refresh })
                needAuth.value = false
                const {
                    access: {
                        actions: accessActions
                    }
                } = jwtDecode(accessToken.value as string) as any;
                console.log(accessActions)
                localStorage.setItem('actions', JSON.stringify(accessActions))
                actions.value = accessActions
            }).then(() => window.location.reload())
    }

    const logout = () => {
        accessToken.value = ''
        refreshToken.value = ''
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        needAuth.value = true
    }

    return { accessToken, refreshToken, returnUrl, needAuth, isAvailableComments, setRefreshedTokens, login, logout }
})
