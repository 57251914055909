import { client } from "./api.service";

export const login = (email: string, password: string) => {
    return client("authService").then(res => res.post(
        "auth/general/login",
        {credentials: {username: email, password: password}, type: "email_pwd" },
        { authorization: false }
    ));
}

export default { login }