<template>
  <router-view />
  <v-dialog
      v-model="authStore.needAuth"
      persistent
      rounded
      width="400"
  >
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="user"
                  label="Email*"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="pass"
                  label="Password*"
                  type="password"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
            color="blue-darken-1"
            type="submit"
            @click="submit"
        >
          Войти
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {ref} from "vue";
import {useAuthStore} from "@/store/auth";

const authStore = useAuthStore()

const user = ref('')
const pass = ref('')



const submit = () => {
  authStore.login(user.value, pass.value)
}
</script>

<style lang="scss">
</style>
