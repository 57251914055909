import axios, {AxiosRequestConfig} from "axios";
import {AuthTokens, useAuthStore} from "@/store/auth";
import env from "@/utils/env";
import jwtDecode from "jwt-decode";
import {getServiceName, isXhrError} from "@/utils/helpers";
import {getTraceId} from "@/plugins/nanoid";

declare module 'axios' {
    export interface AxiosRequestConfig {
        authorization?: boolean;
    }
}

export interface CreateAxiosClientOprions {
    options: {
        baseURL: string,
        timeout: number,
        headers: any
    },
    refreshTokenUrl: string,
    getCurrentAccessToken: () => string | null,
    getCurrentRefreshToken: () => string | null,
    logout: () => Promise<void>,
    setRefreshedTokens: (tokens: AuthTokens) => void
}

export type Service = 'authService' | 'canceledService'

export const services: Record<Service, string> = {
    authService: env.get('VUE_APP_AUTH_API') as string,
    canceledService: env.get('VUE_APP_CANCELED_API') as string
}

let failedQueue: any[] = [];
let isRefreshing = false;


const processQueue = (error: any) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    });

    failedQueue = [];
};

export function createAxiosClient({
                                      options,
                                      refreshTokenUrl,
                                      getCurrentAccessToken,
                                      getCurrentRefreshToken,
                                      logout,
                                      setRefreshedTokens,
                                  }: CreateAxiosClientOprions) {
    const client = axios.create(options);

    client.interceptors.request.use(
        (config) => {
            const serviceName = getServiceName(config.baseURL);
            config.headers['x-trace-id'] = getTraceId(serviceName);
            if (config.authorization !== false) {
                const token = getCurrentAccessToken();
                if (token) {
                    config.headers.Authorization = "Bearer " + token;
                }
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    client.interceptors.response.use(
        (response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        (error) => {
            const originalRequest = error.config;
            originalRequest.headers = JSON.parse(
                JSON.stringify(originalRequest.headers || {})
            );
            const refreshToken = getCurrentRefreshToken();
            const isXhr = isXhrError(error);

            if (
                isXhr &&
                [401, 444].includes(error?.response?.status) &&
                !originalRequest._retry
            ) {
                const serviceName = getServiceName(originalRequest.baseURL);

                originalRequest.headers = {
                    ...originalRequest.headers,
                    'x-trace-id': getTraceId(serviceName),
                };
            }

            // If error, process all the requests in the queue and logout the user.
            const handleError = (error: any) => {
                processQueue(error);
                logout();
                return Promise.reject(error);
            };
            // Refresh token conditions
            if (
                refreshToken &&
                error.response?.status === 401 &&
                error.response?.data?.inner.name === 'TokenExpiredError' &&
                originalRequest?.url !== refreshTokenUrl &&
                originalRequest?._retry !== true

            ) {
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({resolve, reject});
                    })
                        .then(() => {
                            return client(originalRequest);
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                }
                isRefreshing = true;
                originalRequest._retry = true;
                const {
                    access: {
                        id: userId, loginType, matchBy
                    }
                } = jwtDecode(getCurrentAccessToken() as string) as any;

                return client
                    .post(refreshTokenUrl, {
                        refreshToken: refreshToken,
                        userId,
                        loginDetails: {
                            type: loginType,
                            matchBy,
                        },
                    })
                    .then((res) => {
                        const tokens = {
                            access: res.data?.access,
                            refresh: res.data?.refresh,
                        };
                        setRefreshedTokens(tokens);
                        processQueue(null);

                        return client(originalRequest);
                    }, handleError)
                    .finally(() => {
                        isRefreshing = false;
                    });
            }

            // Refresh token missing or expired => logout user...
            if (
                error.response?.status === 401 &&
                (error.response?.data?.inner.name === 'TokenExpiredError' || error.response?.data?.inner.name === 'JsonWebTokenError')
            ) {

                return handleError(error);
            }

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            return Promise.reject(error);
        }
    );

    return client;
}

function getCurrentAccessToken() {
    return useAuthStore().accessToken
}

function getCurrentRefreshToken() {
    return useAuthStore().refreshToken
}


function setRefreshedTokens(tokens: AuthTokens) {
    useAuthStore().setRefreshedTokens(tokens)
}

async function logout() {
    useAuthStore().logout()
}

export const client = async (module: Service) => {
    const options = {
        baseURL: services[module],
        timeout: 300000,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const client = axios.create(options);
    const refreshToken = getCurrentRefreshToken();
    const accessToken = getCurrentAccessToken();
    if (accessToken && refreshToken) {
        const {
            access: {
                id: userId, loginType, matchBy
            }, exp: accessExp
        } = jwtDecode(accessToken as string) as any;
        const {exp: refreshExp} = jwtDecode(refreshToken as string) as any;
        const currentSeconds = new Date().valueOf() / 1000;
        if (currentSeconds > refreshExp - 20) {
            logout()
        }
        if (currentSeconds > accessExp - 20) {
            try {
                const refreshRes = await client
                    .post(`${services["authService"]}/auth/tokens/refresh`, {
                        refreshToken: refreshToken,
                        userId,
                        loginDetails: {
                            type: loginType,
                            matchBy,
                        },
                    })
                const tokens = {
                    access: refreshRes.data?.access,
                    refresh: refreshRes.data?.refresh,
                };
                setRefreshedTokens(tokens);
                processQueue(null);
            } catch {
                logout()
            }
        }
    }

    return createAxiosClient({
        options,
        getCurrentAccessToken,
        getCurrentRefreshToken,
        refreshTokenUrl: `${services["authService"]}/auth/tokens/refresh`,
        logout,
        setRefreshedTokens
    })
}